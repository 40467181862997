<template>
  <div class="page1">
    <div class="content">
      <span class="top_name">请先选择要绑定的车辆类型</span>
      <el-select v-model="carType" placeholder="请选择" @change="getCarType">
        <el-option label="请选择" value=""> </el-option>
        <el-option v-for="item in parkType" :key="item.code" :label="item.desc" :value="item.code">
        </el-option>
      </el-select>
    </div>
    <div style="margin-top: 30px">
      <div class="searchWrapper" v-if="searchShow">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item label="道路计费类型">
            <el-select v-model="formInline.rules" placeholder="请选择">
              <el-option label="请选择" value=""> </el-option>
              <el-option
                v-for="item in rulesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Road_name')">
            <my-component
              ref="parkInput"
              :areaIds="''"
              :maxlength="20"
              :dataSource="1"
              :operationId="operationId"
              @valueChange="completeValue"
              slaveRelations="0,1"
            >
            </my-component>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchData" :loading="loading">{{ $t('button.search') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper" v-if="tableShow">
        <el-table
          ref="multipleTable"
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          @selection-change="handleSelectionChange"
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column type="selection" width="58" align="center"> </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper" v-if="pagerShow && tableData.length >= 1">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div style="margin-top: 60px; display: flex">
        <el-button type="primary" @click="allBind" v-if="pagerShow">全部绑定</el-button>
        <div style="text-align: center; flex: 1">
          <el-button type="primary" @click="bindRules" v-if="pagerShow">绑定</el-button>
          <el-button @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  components: {
    myComponent,
  },
  data() {
    return {
      searchShow: false,
      tableShow: false,
      pagerShow: false,
      carType: "",
      formInline: { rules: "", parkName: "", parkId: "" },
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "scopeId",
          label: this.$t("list.charge_type"),
          width: "",
          formatter: (row, column) => {
            let obj = {
              1: "一类区",
              2: "二类区",
              3: "三类区",
            };
            return obj[row.scopeId];
          },
        },
        {
          prop: "feeName",
          label: this.$t("list.Charging_rules"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
      ],
      rulesList: [
        {
          label: "一类区",
          value: "1",
        },
        {
          label: "二类区",
          value: "2",
        },
        {
          label: "三类区",
          value: "3",
        },
      ],
      loading: false,
      tableData: [],
      parkType: [],
      rulesData: {},
      page: 1,
      pageSize: 15,
      total: 0,
      parkListData: [],
      operationId: "", // 营运商ID
    };
  },
  methods: {
    // 选择车辆类型
    getCarType(val) {
      // console.log('选中值:' + val)
      if (!this.searchShow) {
        this.searchShow = true;
      }
      if (this.tableShow) {
        this.tableShow = false;
        this.pagerShow = false;
      }
      this.page = 1;
      this.pageSize = 15;
      this.total = 0;
      this.formInline.rules = "";
      this.formInline.parkName = "";
      this.formInline.parkId = "";
    },
    // 道路过滤
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 车辆类型
    allParkType() {
      // GET /feeScene/carTypeList
      let url = "/acb/2.0/feeScene/carTypeList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.parkType = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 表格数据
    searchData() {
      if (!this.tableShow) {
        this.tableShow = true;
        this.pagerShow = true;
      }
      this.loading = true;
      this.tableData = [];
      if (this.formInline.parkName == "") {
        this.formInline.parkId = "";
      } else {
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
      }
      // vGET /feeScene/pageParkByCondition
      let url = "/acb/2.0/feeScene/pageParkByCondition";
      this.$axios
        .get(url, {
          data: {
            carType: this.carType,
            parentParkId: this.formInline.parkId,
            scopeId: this.formInline.rules,
            pageNum: this.page,
            pageSize: this.pageSize,
            operationId: this.operationId,
            dataSource: "1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.loading = false;
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.loading = false;
          // console.log('报错')
        });
    },
    allBind() {
      this.$refs.multipleTable.toggleAllSelection();
      setTimeout(() => {
        this.bindRules();
      }, 150);
    },
    // 全部绑定
    allBind1() {
      if (this.total == 0) {
        this.$alert("暂无数据", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      this.$confirm("确定要绑定当前" + this.total + "个道路吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/feeSceneMigrate/bacthCreate", {
              data: {
                carType: this.carType,
                feeId: this.$route.query.feeId,
                parentParkId: this.formInline.parkId,
                scopeId: this.formInline.rules,
                operationId: this.operationId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 勾选绑定
    bindRules() {
      if (this.parkListData.length == 0) {
        this.$alert("未选择数据", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      this.$confirm(
        "不建议换绑计费规则，因为换绑计费规则后，会导致异常处理按新规则计费。请确认换绑计费规则吗",
        this.$t('pop_up.Tips'),
        {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$axios
            .post("/acb/2.0/feeSceneMigrate/bacthCreate", {
              data: {
                carType: this.carType,
                feeId: this.$route.query.feeId,
                parkIds: this.parkListData.join(","),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 表格勾选
    handleSelectionChange(selection, row) {
      let select = selection;
      // console.log(select);
      this.parkListData = select.map((v) => {
        return v.parkId;
      });
    },
  },
  mounted() {
    this.operationId = this.$route.query.operationId;
    // console.log('分析道路数据:' + JSON.stringify(this.$route.query.operationId))
    this.allParkType();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.page1
  .content
    margin-top 20px
    text-align center
.top_name
  margin 0 8px 0 0
  display inline-block
  color #666666
.searchWrapper
	overflow hidden
	padding 22px 22px 5px
	margin-bottom 20px
</style>
